import React, { useRef, useState } from 'react'
import { Offcanvas, Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DatePickerComponent from '../Common/DatePickerComponent';

function KycRqstForm(
    {
        show,
        handleClose,
        kycCategories,
        handleCategoryChange,
        selectedCategory,
        handlefileChange,
        handleSubmit,
        expiryDate: date,
        setExpireDate,
        formErr,
        setformErr,
        apiLoading
    }
) {
    const { t } = useTranslation()
    const [isCalenderOpen, setIsCalenderOpen] = useState(false);
    const fileInputRef = useRef(null);
    console.log("==formErr===",formErr)
    const openCalender = () => {
        setIsCalenderOpen(true);
    };
    const closeCalender = () => {
        setIsCalenderOpen(false);
    };
    const handleDateChange = (newDate) => {
        if (newDate) {
            setExpireDate(newDate);
            setformErr('')
        }
    };
    return (
        <Offcanvas show={show} onHide={handleClose} placement='end' className='p-2'>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t('kyc_reqst_form')}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <label htmlFor="category">{t("select_category")}</label>
                <select
                    className={`form-control mb-2 ${formErr?.category ? 'is-invalid' : ''}`}
                    name="category"
                    id="category"
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                >
                    <option key={"first"}>{t("choose_the_category")}</option>
                    {kycCategories?.kycCategory.map((item, key) => (
                        <option key={key} value={item.id}>
                            {item?.category}
                        </option>
                    ))}
                </select>
                <div class="invalid-feedback">
                    {formErr?.category}
                </div>

                <Form.Group controlId="exampleForm.ControlInput1" className='mb-2'>
                    <Form.Label>{t('expiry_date')}</Form.Label>
                    <DatePickerComponent
                        className={"date-picker"}
                        date={date}
                        handleDateChange={handleDateChange}
                        isCalenderOpen={isCalenderOpen}
                        openCalender={openCalender}
                        closeCalender={closeCalender}
                    />
                    <span className='text-danger'>{formErr?.expiryDate}</span>
                </Form.Group>
                <label htmlFor="documentFront">{t("document_both_side")}</label>
                <input
                    ref={fileInputRef}
                    className={`form-control ${formErr?.file ? 'is-invalid' : ''}`}
                    type="file"
                    name="documentFront"
                    id="documentFront"
                    accept="image/jpeg, image/png, image/jpg"
                    multiple
                    onChange={handlefileChange}
                />
                 <div class="invalid-feedback">
                    {formErr?.file}
                </div>
                <p className="info_txt">({t('allowedTypes')})</p>
                
                <Button className='mt-3 w-100' onClick={handleSubmit} disabled={apiLoading}>{t('submit')}</Button>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default KycRqstForm