import React, { useState } from 'react'
import { Modal, Form, Button, ButtonGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import ReCAPTCHA from "react-google-recaptcha";
import mailVerifyImg from '../../assests/images/rb_2148538017(1).png';
import { ApiHook } from '../../hooks/apiHook';
import { toast } from 'react-toastify'
function MailVerificationModal({ show, close }) {
    const { register, setValue, formState: { errors }, trigger, watch } = useForm()
    const formValues = watch()
    const { t } = useTranslation()
    const [captcha, setcaptcha] = useState(null)
    const verifyCaptcha = ApiHook.CallverifyCaptcha()
    const sentVerificationMail = ApiHook.CallsentVerificationMail()
    const [captchaVerified, setcaptchaVerified] = useState(false)
    const [captchaErr, setcaptchaErr] = useState('')
    const captchaChangeHandler = async (value) => {
        setcaptcha(value)
        const response = await verifyCaptcha.mutateAsync({ captcha: value })
        if (response.status) {
            if (response.data.captchaVerified) {
                setcaptchaVerified(true)
                setcaptchaErr('')
            }
        } else {
            setcaptchaVerified(false)
        }
    }
    const submitHandler = async () => {
        if (!captchaVerified) return setcaptchaErr(t('captcha_not_verified'))
        const isValid = await trigger()
        if (isValid && captchaVerified) {
            const response = await sentVerificationMail.mutateAsync({ email: formValues.email })
            console.log("=======sentmail==",response)
            if (response.status) {
                toast.success(t('check_ur_email_and_verify_ur_mail'))
            }
        }


    }
    console.log("========erros==", errors)
    return (
        <Modal show={show} centered backdrop="static">
            <Modal.Header className='mail_verify_mdodal_header'>

                <div className='row w-100 align-items-center'>
                    <div className='col-lg-8'>
                        <img src={mailVerifyImg} className='w-100' style={{ marginTop: '-1em' }} />

                    </div>
                    <div className='col-lg-4 d-flex flex-column justify-content-center align-items-start'>
                        <h2>{t('email')}</h2>
                        {/* <br /> */}
                        <h5>verification</h5>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body style={{ padding: '2em' }}>
                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <sup className='text-danger'>٭</sup>
                        <Form.Label>{t('email')}</Form.Label>

                        <Form.Control type="email" placeholder="Enter email"
                            {...register('email', { required: { value: true, message: t('this_field_is_required') } })}
                            isInvalid={!!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors?.email?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form>
                <div className='google-captcha'>
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        onChange={captchaChangeHandler}
                    />
                    <span className='text-danger' style={{ fontSize: '15px' }}>{captchaErr}</span>
                </div>
            </Modal.Body>
            <ButtonGroup aria-label="Basic example" className='mail_verify_btn_group'>
                <Button variant="primary" style={{ background: '#ff8701', color: 'black', fontWeight: 'bold' }} onClick={submitHandler}>Submit</Button>
                <Button variant="dark" style={{ fontWeight: 'bold' }} onClick={close}>Close</Button>

            </ButtonGroup>

        </Modal>
    )
}

export default MailVerificationModal