// const BASE_URL = "http://192.168.21.2:5000/node_api/";
//demo
const BASE_URL = "https://tamer.admin.iossmlm.com/node_api/";

//live
// const BASE_URL = "https://admin.icare-net.net/node_api/";
const DEFAULT_KEY = '02ee7e4e-8694-4bf9-841f-2e3781f0e65'

const PAYPAL_CLIENT_ID = "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75"
// dsfsdf
export { BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID }
