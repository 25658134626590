import React from 'react'
import { useTranslation } from 'react-i18next';
import TableMain from '../Common/table/TableMain';
import { getLastPage } from '../../utils/getLastPage';
function RankClassTable(props) {
    const { t } = useTranslation()
    const headers = [
        t("count"),
        t("leg_points"),
        t("position"),
        t("date"),
    ];
    const lastPage = getLastPage(props.itemsPerPage, props?.data?.totalCount);

    return (
        <div className="table-responsive rank-cls-table">
            <TableMain
                headers={headers}
                data={props.data?.data}
                startPage={1}
                currentPage={props.currentPage}
                totalPages={lastPage}
                type={props.type}
                itemsPerPage={props.itemsPerPage}
                setItemsPerPage={props.setItemsPerPage}
                setCurrentPage={props.setCurrentPage}
                setEpinSortOptions={props.setSortOptions}
                totalEntries={props?.data?.totalCount}
            />
        </div>
    )
}

export default RankClassTable