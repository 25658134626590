import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import fundtrandfer from '../../assests/images/fundtransfer.png'
import { useTranslation } from 'react-i18next'
import {useSelector} from 'react-redux'

function FundconfirmModal({ show, close, fundTranferDetails, currency, setconfirmStatus, submitRef, handleSubmit,apiLOading }) {
    const { t } = useTranslation()
    const user = useSelector(
        (state) => state.dashboard?.appLayout?.user
      );
    const checkConfirmStatus = async(status, e) => {
        console.log("==status==",status)
        setconfirmStatus(status)
        if (status) {
            // console.log("==enter here")
            // submitRef.current(); // Trigger the submit function
            await handleSubmit(e,status)
        }
        close()
    }
    console.log("=======appuser====",user)
    return (
        <Modal show={show} onHide={close} backdrop="static" >
            <Modal.Header closeButton />
            <Modal.Body>
                <h3>{t('fund_tranfer')}</h3>
                <p
                    dangerouslySetInnerHTML={{
                        __html: t('do_u_want_to_fund_transfer', {
                            currency: currency?.symbolLeft,
                            amount: fundTranferDetails?.amount,
                            username:`${fundTranferDetails?.fullname}(${fundTranferDetails?.username})`
                        }),
                    }}
                ></p>

                <img src={fundtrandfer} className='w-100' />
                <div className='confirm-btns'>
                    <Button onClick={(e) => checkConfirmStatus(true, e)} disabled={apiLOading}>{t('yes')}</Button>
                    <Button variant='dark' onClick={(e) => checkConfirmStatus(false, e)} disabled={apiLOading}>{t('No')}</Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default FundconfirmModal