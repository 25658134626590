import React, { useState } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import CurrencyConverter from "../../../Currency/CurrencyConverter";
import { useSelector } from "react-redux";
import Select from 'react-select'
import { ApiHook } from "../../../hooks/apiHook";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
const ContestDetailsModal = ({ show, handleClose, /*contestDetails*/ contests }) => {
    const { t } = useTranslation();
    const userContestDtails = ApiHook.CalluserContestDetails()
    const navigate = useNavigate()
    const [contestType, setcontestType] = useState(null)
    const [contestDetails, setcontestDetails] = useState()
    const toHistory = () => {
        navigate('/contest-history', { state: { contestId: contestType?.value } })
    }
    const conversionFactor = useSelector(
        (state) => state?.user?.conversionFactor
    );
    const userSelectedCurrency = useSelector(
        (state) => state.user?.selectedCurrency
    );
    const selectContest = async (val) => {
        setcontestType(val)
        const response = await userContestDtails.mutateAsync({ contestId: val?.value })
        console.log("===response===", response)
        if (response.status) {
            setcontestDetails(response?.data)
        }
    }

    return (
        <Modal show={show} onHide={handleClose} backdrop="static">
            <Modal.Header>
                {/* <Modal.Title as={"h5"}>{t("contestDetails")}</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>{t('Contest_type')}</Form.Label>
                    <Select
                        options={contests}
                        value={contestType}
                        onChange={selectContest}
                    />
                </Form.Group>
                {
                    userContestDtails?.isLoading && <span>{t('loading')}</span>
                }
                {
                    contestDetails &&
                    <ul className="list-group">
                        {Object.entries(contestDetails).map(([key, value]) => (
                            <li key={key} className="list-group-item">
                                <Row>
                                    <Col><strong>{t(key)}</strong></Col>
                                    <Col>
                                        {["salesAchieved", "businessVolTarget", "businessVolAchieved"].includes(key) ? (
                                            `${userSelectedCurrency?.symbolLeft} ${CurrencyConverter(value, conversionFactor)}`
                                        ) : key === "contestType" ? (
                                            t(value)
                                        ) : key === "userProgress" ? (
                                            <div className="w-25">
                                                <CircularProgressbar value={value} text={`${value}%`} 
                                                />

                                            </div>
                                        ) : (
                                            value
                                        )}
                                    </Col>
                                </Row>
                            </li>
                        ))}
                    </ul>

                }

            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={toHistory} disabled={!contestDetails}>
                    {t("contestDetails")}
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                    {t("close")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ContestDetailsModal;
