import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import star from '../../assests/images/star.png'
import { Link } from 'react-router-dom'
function ClassComponent({ classDetails }) {
    console.log("==classDetails===", classDetails)
    const { t } = useTranslation()
    const [activeTab, setActiveTab] = useState("class");
    return (
        <div className="col-md-5">
            <div className="ranking-box">
                <div className="joinings_viewBox_head">
                    <h5>{t("class")}</h5>
                </div>
                <ul
                    className="teamPerfomance_tab nav nav-tabs mb-3"
                    id="ex1"
                    role="tablist"
                >
                    <li className="nav-item" role="presentation">
                        <Link
                            className={`nav-link ${activeTab === "class" ? "active" : ""}`}
                            id="ex1-tab-3"
                        >
                            {t("current_cls")}
                        </Link>
                    </li>
                </ul>
                <div className="tab-content" id="ex1-content">
                    <div
                        className={`tab-pane ${activeTab === "class" ? "active" : ""}`}
                        id="rank"
                        role="tabpanel"
                        aria-labelledby="rank"
                    >
                        <div className="class_section text-center py-4">
                            {
                                (classDetails?.className &&
                                    classDetails?.position) ?
                                    <>
                                        <h4 className="supervisor-title mb-3">{classDetails?.className}</h4>
                                        <h6 className="position-info">
                                            Position:
                                            <span className="position-circle">{classDetails?.position}</span>
                                        </h6>
                                    </>

                                    :
                                    <h4 className="supervisor-title mb-3">{t('no_active_cls')}</h4>

                            }

                        </div>
                    </div>
                </div>

            </div>
        </div>

    )
}

export default ClassComponent