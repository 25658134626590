import React from "react";
import { Modal } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useTranslation } from "react-i18next";
import trophy from '../../assests/images/trophy.png'
import medal from '../../assests/images/medal(1).png'
import CurrencyConverter from "../../Currency/CurrencyConverter";
import { useSelector } from 'react-redux'

function PrizeDetails({ show, closePrizeModal, contestPrizeDetails }) {
    const { t } = useTranslation();
    const conversionFactor = useSelector(
        (state) => state?.user?.conversionFactor
    );
    const currency = useSelector(
        (state) => state.user?.selectedCurrency
    );

    const carouselOptions = {
        loop: true,
        margin: 10,
        // nav: true,
        dots: true,
        responsive: {
            0: { items: 1 },
            600: { items: 2 },
            1000: { items: 3 },
        },
    };

    const prizes = [
        {
            position: 1,
            prizeType: "amount",
            image: "https://via.placeholder.com/100",
            designation: "Winner",
            amount: 100000,
            description: "test fgdfgdfg"
        },
        {
            position: 2,
            prizeType: "gift",
            image: "https://via.placeholder.com/100",
            designation: "Winner",
            amount: 50000,
            description: "test fgdfgdfg"
        },
        {
            position: 3,
            prizeType: "amount",
            image: "https://via.placeholder.com/100",
            designation: "Winner",
            amount: 5000,
            description: "test fgdfgdfg"
        },
    ];
    const getPositionSuffix = (position) => {
        // Handle 11th, 12th, 13th, etc. which are exceptions
        const j = position % 10;
        const k = position % 100;
        if (j === 1 && k !== 11) {
            return 'st';
        }
        if (j === 2 && k !== 12) {
            return 'nd';
        }
        if (j === 3 && k !== 13) {
            return 'rd';
        }
        return 'th';
    };

    return (
        <Modal show={show} centered size="lg" onHide={closePrizeModal}>
            <Modal.Header closeButton style={{ border: 'none' }} />
            <Modal.Body>
                <OwlCarousel className="owl-theme" {...carouselOptions}>
                    {contestPrizeDetails.map((prize, index) => (
                        <div className="item" key={index}>
                            <figure className="snip1192">
                                <img src={medal} alt={prize.author} />
                                <h1 style={{ color: 'white' }}>
                                    {prize.position}
                                    {/* <span><sup>{getPositionSuffix(prize.position)}</sup></span> */}
                                    <span>
                                        <sub>Prize ,</sub>
                                    </span>
                                </h1>
                                <div className="priz-details">
                                    {
                                        prize.prizeType === "amount" ?
                                            <>
                                                <span style={{ textTransform: 'capitalize' }}>
                                                    {prize.prizeType}
                                                </span>
                                                <h4 style={{ fontFamily: 'Montserrat, sans-serif', fontWeight: 'bold' }}>
                                                    {currency?.symbolLeft}{" "}
                                                    {CurrencyConverter(
                                                        prize.amount,
                                                        conversionFactor
                                                    )}
                                                </h4>
                                                <p>{prize.description}</p>
                                            </>

                                            :
                                            <>
                                                <span style={{ textTransform: 'capitalize' }}>
                                                    {prize.prizeType}
                                                </span>
                                                <h4 style={{ fontFamily: 'Montserrat, sans-serif', fontWeight: 'bold' }}>
                                                    {currency?.symbolLeft}{" "}
                                                    {CurrencyConverter(
                                                        prize.amount,
                                                        conversionFactor
                                                    )}
                                                </h4>
                                                <p>{prize.description}</p>
                                            </>

                                    }
                                </div>
                            </figure>
                        </div>
                    ))}
                </OwlCarousel>
            </Modal.Body>
        </Modal>
    );
}

export default PrizeDetails;
