import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom'
import { ApiHook } from '../../hooks/apiHook';

function VerifyMail() {
    const { t } = useTranslation()
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const verifyMail = ApiHook.CallVerifyMail(token)
    console.log("==verifyMail====", verifyMail?.data)
    return (
        <div className="d-flex justify-content-center align-items-center min-vh-100">
            <Card className="border-0 w-50 verify-mail-container">
                <Card.Header className="border-0" style={{ background: 'white' }}>
                    <img src="/images/iCareNet_logo_page-0001-removebg-preview.png" style={{ width: '160px' }} />
                </Card.Header>
                {
                    verifyMail.isLoading ?
                        <Card.Body>
                            <div>
                                <div className="spinner">
                                    <div className="spinner-bounce one"></div>
                                    <div className="spinner-bounce two"></div>
                                    <div className="spinner-bounce three"></div>
                                </div>
                            </div>
                        </Card.Body>
                        :
                        <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                            {
                                verifyMail?.data?.data?.code == 1140 ?
                                    <>
                                        <h2 style={{ fontFamily: 'Lora,serif', fontWeight: '700', color: 'rgb(226, 53, 53)' }}>{t('the_verification_link_has_expired')}</h2>
    
                                        <p>{t('please_request_a_new_link')}</p>
                                        <p>{t('if_you_need_help_contact_support')}</p>
                                    </>
                                    :
                                    <>
                                        <h2 style={{ fontFamily: 'Lora,serif', fontWeight: '700', color: '#e27835' }}>{t('mail_verified')}</h2>
                                        <p>{t('thank_you_for_verifying')}</p>
                                        <p>{t('you_can_now_register')}</p>
                                    </>

                            }

                        </Card.Body>

                }

            </Card>
        </div>

    );
}

export default VerifyMail;
